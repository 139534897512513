import React from 'react'

const Nopage = () => {
  return (
    <div>
        <h1>Nopage</h1>
        <h3>Visible to public when route not found</h3>
    </div>
  )
}

export default Nopage